<template>
  <el-card shadow="never">
    <div>
      <el-page-header @back="goBack">
        <template #content>
          <span class="text-large font-600 mr-3"> 军团 </span>
        </template>
        <template #extra>
          <div class="flex items-center">
            <el-button @click="showaddcatebox" type="primary">添加</el-button>
          </div>
        </template>
      </el-page-header>
    </div>
  </el-card>
  <el-card shadow="never" style="margin-top:20px">
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="id" width="55" />
      <el-table-column prop="title" label="标题" />
      <el-table-column prop="sort" label="排序" width="100" />
      <el-table-column prop="addTime" label="添加时间" width="210"/>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button size="small" @click="EditJt(scope.row)">编辑</el-button>
          <el-button
            size="small"
            type="danger"
            @click="deleteJt(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="float:right;margin:20px">
      <el-pagination
        v-model:page-size="pageSize"
        v-model:currentPage="current_page"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-card>
  <el-dialog v-model="catebox" :title="title" width="60%" draggable>
    <el-form :model="formData" label-width="80px">
      <el-form-item label="标题">
        <el-input v-model="formData.title" />
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="formData.sort" :min="0" :max="99" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="setJt">确定</el-button>
        <el-button @click="catebox = false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import api from '@/api/index.js'
export default defineComponent({
  setup() {
    let formData = ref({
      id:'',
      title:'',
      sort:0,
    })
    let page = ref(1)
    let pageSize = ref(10)
    let catebox = ref(false)
    let title = ref('添加职位')
    let total = ref(0)
    let current_page = ref(1)
    const handleSelectionChange = () => {
      console.log('go back')
    }
    const tableData = ref([])

    return {
      page,
      pageSize,
      current_page,
      tableData,
      formData,
      title,
      catebox,
      total,
      handleSelectionChange
    };
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    handleCurrentChange(val){
      this.page = val
      this.getList()
    },
    deleteJt(id){
      let that = this
      api.memberApi.deleteJt({id:id}).then(res => {
          if (res.code === 0) {
            ElMessage({
              message: res.message,
              type: 'success',
            })
            that.getList()
          }else{
            ElMessage.error(res.message)
          }
      }).catch((res) => {
          ElMessage.error(res.message);
      })
    },
    getList(){
      let that = this
      api.memberApi.getJtList({page:that.page}).then(res => {
          if (res.code === 0) {
            that.pageSize = res.data.per_page
            that.tableData = res.data.data
            that.total = res.data.total
            that.current_page = res.data.current_page
          }else{
            ElMessage.error(res.message)
          }
      }).catch((res) => {
          ElMessage.error(res.message);
      })
    },
    EditJt(info){
      this.title = '编辑班委'
      this.catebox = true
      this.formData.id = info.id
      this.formData.title = info.title
      this.formData.sort = info.sort
    },
    showaddcatebox(){
      this.title = '添加班委'
      this.catebox = true
      this.formData.id = ''
      this.formData.title = ''
      this.formData.sort = 0
    },
    setJt(){
      let that = this
      api.memberApi.setJt(this.formData).then(res => {
          if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })
              that.catebox = false
              that.getList()
          }else{
            ElMessage.error(res.message)
          }
      }).catch((res) => {
          ElMessage.error(res.message);
      })
    }
  },
  mounted () {
    this.getList()
  }
})
</script>
<style>

</style>